import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Layout from '../components/common/layout';
import SEO from '../components/common/seo';
import Banner from '../components/common/hero';
import HomeSvg from '../assets/svgs/home.svg';

const render = <HomeSvg />;

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Digital solutions"
        description="Whether its an app, a website, e-commerce or a chat bot - bring your business into the digital age with digital solutions from Hyper Pixel."
      />
      <Layout>
        <Banner
          heading="keeping up with tech can be tough in the 21st century"
          description="You manage your business and we'll do the rest"
          ctaLink={`#`}
          ctaText="Lets get started"
        >
          {render}
        </Banner>
      </Layout>
    </>
  );
};

export default IndexPage;
